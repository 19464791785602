.slider3dSlide {
  background-color: #ccc;
  background-size: cover;
  border-color: rgba(0, 0, 0, 0.4);
  border-radius: 1px;
  border-style: solid;
  box-sizing: border-box;
  display: block;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  text-align: left;
  top: 0;
  visibility: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  &.current {
    opacity: 1 !important;
    visibility: visible !important;
    transform: none !important;
    z-index: 1001;
  }
}