.slider3dContainer {
  box-sizing: border-box;
  margin: 20px auto;
  min-height: 1px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
}

.slider3dSlider {
  -moz-perspective: 1000px;
  -webkit-perspective: 1000px;
  margin: 0 auto;
  perspective: 1000px;
  position: relative;
  transform-style: preserve-3d;
}
