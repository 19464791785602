.slider3dControls {
  height: 0;
  left: 0;
  margin-top: -30px;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 1000;
}

.next,
.prev {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  color: #333;
  font-size: 60px;
  height: 60px;
  line-height: 60px;
  position: absolute;
  text-decoration: none;
  top: 0;
  user-select: none;
  width: 60px;
  z-index: 1010;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.prev {
  left: 10px;
  text-align: left;
}

.next {
  right: 10px;
  text-align: right;
}

.disabled {
  opacity: 0.2;
  cursor: default;
}

.disabled:hover {
  cursor: default;
  opacity: 0.2;
}